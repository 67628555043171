/* page[size="A4"] {  
  width: 21cm;
  height: 29.7cm; 
} */
.word-elips {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 30px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
}
