

.parent_map {
  height: 100vh;
  width: 100%;
}

.circle {
  border-radius: 50%;
  color: #fff;
  height: 60px;
  position: relative;
  width: 60px;
  border: 1px solid transparent;
  position: relative;
}

.circleText {
  text-align: center;
  left: 10%;
  position: absolute;
  top: -28px;
  transform: translateX(-50%);
  width: 100%;
  border: 0;
  display: none;
  font-size: 12px;
  font-weight: 500;
}


.circle .circleText{
  display: block;
  position: absolute;
  left: 50%;
  bottom: 2.65em;
  transform: translateX(-60%);
  width: max-content;
  border-radius: 0.25em;
  padding: 0.5em;
  background-color: rgba(25,25,25);
  backdrop-filter: blur(2px) invert(80%);
  color: #eee;
  opacity: 0;
  scale: 0;
  transition: opacity, scale 0.3s;
  transform-origin: left bottom;
  height: 22px;
  width: 100px;
  font-size: 11px;
  font-weight: 500;
}
.circle .circleText::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -0.25em;
  transform: translateX(-50%);
  border: 0.35em solid rgba(0, 0, 0, 0);
  border-top-color: #000;
  border-bottom-width: 0;
}
.circle:hover .circleText {
  opacity: 1;
  scale: 1;
}









